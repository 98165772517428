import { config } from '../config';
import { httpclient } from '@fastbot/utils';
import { Credentials, PasswordReset, PasswordResetConfirm, RequestHeaders, TokenResponse, User } from '../models';

export const storeToken = (response: TokenResponse) => {
    window.localStorage.setItem(config.key, JSON.stringify(response));
};

export const removeToken = () => {
    window.localStorage.removeItem(config.key);
};

export const storeUser = (user: User) => {
    const token = loadToken();
    const result: any = { ...token, ...user };
    storeToken(result);
};

export const loadToken = (): TokenResponse => {
    return (JSON.parse(window.localStorage.getItem(config.key)!) || {}) as TokenResponse;
};

export const authHeaders = (): any => {
    const token = loadToken();
    return {
        [RequestHeaders.CONTENT_TYPE]: RequestHeaders.JSON,
        [RequestHeaders.AUTHORIZATION]: `Token ${token.token}`,
    };
};

export const serviceLogIn = (credentials: Credentials) => {
    return httpclient.json(`${config.api}/accounts/create-token`, {
        method: 'POST',
        headers: {
            [RequestHeaders.CONTENT_TYPE]: RequestHeaders.JSON,
        },
        body: JSON.stringify(credentials),
    });
};

export const resetPassword = async (passwordReset: PasswordReset): Promise<any> => {
    const body = JSON.stringify(passwordReset);
    return await httpclient.json(`${config.api}/accounts/reset_password`, {
        method: 'POST',
        headers: {
            [RequestHeaders.CONTENT_TYPE]: RequestHeaders.JSON,
        },
        body,
    });
};

export const resetPasswordConfirm = async (passwordResetConfirm: PasswordResetConfirm): Promise<any> => {
    const body = JSON.stringify(passwordResetConfirm);
    return await httpclient.json(`${config.api}/accounts/reset_password_confirm`, {
        method: 'POST',
        headers: {
            [RequestHeaders.CONTENT_TYPE]: RequestHeaders.JSON,
        },
        body,
    });
};
