import React, { Suspense } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './App.css';
import { Link, Route, Routes, useParams } from 'react-router-dom';
import createRouters from './routers';
import DocumentTitle from 'react-document-title';
import { Avatar, Breadcrumb, Card, Dropdown, Layout, Menu, Skeleton, Spin } from 'antd';
import { ConnectedRouter } from 'connected-react-router';
import template from 'url-template';
import { Menu as AppMenu, TokenResponse } from './models';
import { loadToken, removeToken } from './services/AuthService';
import { LogoutOutlined, UpOutlined, UserOutlined } from '@ant-design/icons';
import { Provider } from 'react-redux';
import configureStore, { history } from './store';
import SubMenu from 'antd/es/menu/SubMenu';
import { SignInForm, SignUpForm } from './forms';
import PasswordResetForm from './forms/PasswordResetForm';
import PasswordResetFormConfirm from './forms/PasswordResetFormConfirm';
import { PaymentCancel, PaymentSuccess } from './components';
import Page500 from './components/Page500';
import { LogoComponent } from './images';
import { BrowserRouter } from 'react-router-dom';

const store = configureStore();

interface AppState {
    token: TokenResponse | null;
    collapsed: boolean;
}

interface AppProps {
    push: (arg: any) => void;
}

export const BreadLayout = ({ menu }: { menu: AppMenu }) => {
    const params = useParams();
    return (
        <Breadcrumb style={{ paddingBottom: '10px' }}>
            {menu.breadcrumbs?.map((breadcrumb) => {
                if (breadcrumb.icon) {
                    return (
                        <Breadcrumb.Item key={breadcrumb.path}>
                            <Link to={breadcrumb.path!}>{breadcrumb.icon}</Link>
                        </Breadcrumb.Item>
                    );
                }
                if (breadcrumb.match) {
                    const name = template.parse(breadcrumb.name).expand(params);
                    const path = template.parse(breadcrumb.path).expand(params);
                    return (
                        <Breadcrumb.Item key={breadcrumb.path}>
                            <Link to={path} dangerouslySetInnerHTML={{ __html: name }} />
                        </Breadcrumb.Item>
                    );
                }

                return (
                    <Breadcrumb.Item key={breadcrumb.path}>
                        <Link to={breadcrumb.path!}>{breadcrumb.name}</Link>
                    </Breadcrumb.Item>
                );
            })}
        </Breadcrumb>
    );
};

export default class App extends React.Component<any, AppState> {
    state = {
        token: null,
        collapsed: false,
    };

    componentDidMount = () => {
        const token = loadToken();
        if (token.token) {
            this.setState({
                token: token,
            });
        }
    };

    onChange = (token: TokenResponse) => {
        this.setState({ token: token });
    };

    logout = () => {
        removeToken();
        this.setState({ token: null }, () => {
            history.push('/login');
        });
    };

    render() {
        const routers = createRouters();
        const info: any = loadToken();

        const menus = (
            <Menu>
                <Menu.Item icon={<UserOutlined />}>{info.username}</Menu.Item>
                {info.activeSubscription ? (
                    <Menu.Item icon={<UpOutlined />}>
                        <Link to={'/plans'}>Upgrade</Link>
                    </Menu.Item>
                ) : null}
                <Menu.Item icon={<LogoutOutlined />}>
                    <Link to="/logout" onClick={() => this.logout()}>
                        Logout
                    </Link>
                </Menu.Item>
            </Menu>
        );

        const allMenus: AppMenu[] = [];
        routers.menus.forEach((menu) => {
            allMenus.push(menu);
            menu.submenus?.forEach((subMenu) => {
                allMenus.push(subMenu);
            });
        });

        const SideBar = routers.menus
            .filter((menu) => menu.sideBar)
            .map((menu, index: number) => {
                if (menu.submenus) {
                    return (
                        <SubMenu key={index} title={menu.title} icon={menu.icon}>
                            {menu.submenus?.map((subMenu, subIndex) => (
                                <Menu.Item key={`${subIndex + 1}`}>
                                    <Link to={subMenu.path!}>{subMenu.title}</Link>
                                </Menu.Item>
                            ))}
                        </SubMenu>
                    );
                }
                return (
                    <Menu.Item
                        style={{ fontSize: '1.25em', verticalAlign: 'bottom' }}
                        key={`${index + 1}`}
                        icon={menu.icon}
                    >
                        <Link to={menu.path!}>{menu.title}</Link>
                    </Menu.Item>
                );
            });

        const RouteList = allMenus.map((menu, key) => {
            const { site } = routers;
            const { component: Component } = menu;

            const { exact, path, title } = menu;
            const { token } = this.state;

            return (
                <Route
                    key={`${path}-${title}`}
                    path={path || ''}
                    element={
                        <DndProvider backend={HTML5Backend}>
                            {token ? (
                                <DocumentTitle title={`${site.name}: ${menu.title}`}>
                                    <Layout style={{ minHeight: '100vh' }}>
                                        <Layout.Sider
                                            onBreakpoint={(broken) => {
                                                console.log(broken);
                                            }}
                                            onCollapse={(collapsed, type) => {
                                                console.log(collapsed, type);
                                                this.setState({ collapsed });
                                            }}
                                            collapsible
                                            collapsed={this.state.collapsed}
                                        >
                                            <LogoComponent
                                                className="logo"
                                                height={'32px'}
                                                width={'32px'}
                                                style={{ margin: '32px' }}
                                            />
                                            <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
                                                {SideBar}
                                            </Menu>
                                        </Layout.Sider>
                                        <Layout>
                                            <Layout.Header
                                                // className="site-layout-sub-header-background"
                                                style={{ padding: 0, background: '#fff' }}
                                            >
                                                <div
                                                    className="ant-dropdown-link"
                                                    style={{
                                                        float: 'right',
                                                        color: 'white',
                                                        paddingRight: '40px',
                                                    }}
                                                >
                                                    {/* <Avatar
                      size={"small"}
                      style={{
                        backgroundColor: "transparent",
                        color: "orange",
                        verticalAlign: "middle",
                        cursor: "point",
                      }}
                      icon={<NotificationOutlined color={"red"} />}
                    ></Avatar>

                    <Avatar
                      size={"small"}
                      style={{
                        backgroundColor: "transparent",
                        color: "orange",
                        verticalAlign: "middle",
                        cursor: "point",
                      }}
                      icon={<SearchOutlined />}
                    ></Avatar>

                    <Avatar
                      size={"small"}
                      style={{
                        backgroundColor: "transparent",
                        color: "orange",
                        verticalAlign: "middle",
                        cursor: "point",
                      }}
                      icon={<BellOutlined />}
                    ></Avatar> */}
                                                    <Dropdown
                                                        overlay={menus}
                                                        arrow={false}
                                                        overlayStyle={{ minHeight: '200px' }}
                                                    >
                                                        <Avatar
                                                            style={{
                                                                verticalAlign: 'middle',
                                                                cursor: 'point',
                                                            }}
                                                            size="large"
                                                            gap={1}
                                                        >
                                                            {info.username && info.username[0].toUpperCase()}
                                                        </Avatar>
                                                    </Dropdown>
                                                </div>
                                            </Layout.Header>
                                            <Layout.Content style={{ margin: '24px 16px 0' }}>
                                                <div
                                                    className="site-layout-background"
                                                    style={{ padding: 24, minHeight: 360 }}
                                                >
                                                    <BreadLayout menu={menu} />
                                                    <Page500
                                                        isOnline={window.navigator.onLine}
                                                        location={window.location}
                                                    >
                                                        <Suspense
                                                            fallback={
                                                                <Card>
                                                                    <Skeleton active={true}>
                                                                        <Skeleton.Button />
                                                                    </Skeleton>
                                                                </Card>
                                                            }
                                                        >
                                                            <Component {...this.props} />
                                                        </Suspense>
                                                    </Page500>
                                                </div>
                                            </Layout.Content>
                                            <Layout.Footer style={{ textAlign: 'center' }}>Fastbot ©2021</Layout.Footer>
                                        </Layout>
                                    </Layout>
                                </DocumentTitle>
                            ) : (
                                <SignInForm onChange={this.onChange} site={site} />
                            )}
                        </DndProvider>
                    }
                />
            );
        });

        return (
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <BrowserRouter>
                        <Routes>
                            {RouteList}
                            <Route
                                path="/login"
                                element={<SignInForm onChange={this.onChange} site={routers.site} />}
                            />

                            <Route path={'/signup'} element={<SignUpForm site={routers.site} />} />

                            <Route path={'/password_reset'} element={<PasswordResetForm site={routers.site} />} />

                            <Route
                                path={'/payments/success'}
                                element={
                                    <Suspense fallback={<Spin />}>
                                        <PaymentSuccess site={routers.site} />
                                    </Suspense>
                                }
                            />

                            <Route
                                path={'/payments/cancel'}
                                element={
                                    <Suspense fallback={<Spin />}>
                                        <PaymentCancel site={routers.site} />
                                    </Suspense>
                                }
                            />

                            <Route
                                path={'/password_reset_confirm/:uid/:token'}
                                element={<PasswordResetFormConfirm site={routers.site} />}
                            />
                        </Routes>
                    </BrowserRouter>
                </ConnectedRouter>
            </Provider>
        );
    }
}
