import React from 'react';
import activity, { ReactComponent as ActivityComponent } from './activity.svg';
import africastalking, { ReactComponent as AfricastalkingComponent } from './africastalking.svg';
import goat_403, { ReactComponent as Goat_403Component } from './goat_403.svg';
import goat_404, { ReactComponent as Goat_404Component } from './goat_404.svg';
import goat_500, { ReactComponent as Goat_500Component } from './goat_500.svg';
import goat_503, { ReactComponent as Goat_503Component } from './goat_503.svg';
import kik, { ReactComponent as KikComponent } from './kik.svg';
import line, { ReactComponent as LineComponent } from './line.svg';
import logo, { ReactComponent as LogoComponent } from './logo.svg';
import messenger, { ReactComponent as MessengerComponent } from './messenger.svg';
import microsoft, { ReactComponent as MicrosoftComponent } from './microsoft.svg';
import offline, { ReactComponent as OfflineComponent } from './offline.svg';
import robot, { ReactComponent as RobotComponent } from './robot.svg';
import slack, { ReactComponent as SlackComponent } from './slack.svg';
import teams, { ReactComponent as TeamsComponent } from './teams.svg';
import telegram, { ReactComponent as TelegramComponent } from './telegram.svg';
import twilio, { ReactComponent as TwilioComponent } from './twilio.svg';
import twitter, { ReactComponent as TwitterComponent } from './twitter.svg';
import viber, { ReactComponent as ViberComponent } from './viber.svg';
import umbrella, { ReactComponent as UmbrellaComponent } from './umbrella.svg';
import mfa, { ReactComponent as MfaComponent } from './mfa.svg';

export type SvgComponentType = React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;

export {
    activity,
    ActivityComponent,
    africastalking,
    AfricastalkingComponent,
    goat_403,
    Goat_403Component,
    goat_404,
    Goat_404Component,
    goat_500,
    Goat_500Component,
    goat_503,
    Goat_503Component,
    kik,
    KikComponent,
    line,
    LineComponent,
    logo,
    LogoComponent,
    messenger,
    MessengerComponent,
    microsoft,
    MicrosoftComponent,
    offline,
    OfflineComponent,
    robot,
    RobotComponent,
    slack,
    SlackComponent,
    teams,
    TeamsComponent,
    telegram,
    TelegramComponent,
    twilio,
    TwilioComponent,
    twitter,
    TwitterComponent,
    viber,
    ViberComponent,
    umbrella,
    UmbrellaComponent,
    mfa,
    MfaComponent,
};

export type ChannelType =
    | 'africastalking'
    | 'kik'
    | 'line'
    | 'messenger'
    | 'microsoft'
    | 'slack'
    | 'teams'
    | 'telegram'
    | 'twilio'
    | 'twitter'
    | 'viber'
    | 'gupshup';

export const channelTypes: ChannelType[] = [
    'africastalking',
    'kik',
    'line',
    'messenger',
    'microsoft',
    'slack',
    'teams',
    'telegram',
    'twilio',
    'twitter',
    'viber',
    'gupshup',
];

export const urls: Record<string, string> = {
    africastalking: africastalking,
    kik: kik,
    line: line,
    messenger: messenger,
    microsoft: microsoft,
    slack: slack,
    teams: teams,
    telegram: telegram,
    twilio: twilio,
    twitter: twitter,
    viber: viber,
    gupshup: umbrella,
};

export const components: Record<string, SvgComponentType> = {
    africastalking: AfricastalkingComponent,
    kik: KikComponent,
    line: LineComponent,
    messenger: MessengerComponent,
    microsoft: MicrosoftComponent,
    slack: SlackComponent,
    teams: TeamsComponent,
    telegram: TelegramComponent,
    twilio: TwilioComponent,
    twitter: TwitterComponent,
    viber: ViberComponent,
    gupshup: UmbrellaComponent,
};
