import React, { ErrorInfo } from 'react';
import { Button, Result, Typography } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import DocumentTitle from 'react-document-title';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import config from '../config';
import { getGithubIssueUrl } from '@fastbot/utils/lib/github';

export interface OfflineBoundaryProps {
    isOnline: boolean;
    location: any;
    children: React.ReactNode;
    fallback?: React.ReactNode;
}

export interface OfflineBoundaryState {
    hasError: boolean;
    showDetails: boolean;
    isReported: boolean;
    error?: Error;
}

export default class Page500 extends React.Component<OfflineBoundaryProps, OfflineBoundaryState> {
    constructor(props: OfflineBoundaryProps) {
        super(props);
        this.state = { hasError: false, showDetails: false, isReported: true };
    }

    static getDerivedStateFromError(error: Error): Partial<OfflineBoundaryState> | null {
        return { hasError: true, error };
    }

    componentDidCatch = (error: Error, errorInfo: ErrorInfo) => {
        this.setState({ hasError: true, error });
    };

    handleReload = () => {
        (window as any).location.reload(true);
    };

    handleShowDetails = () => {
        console.log(this.state);
        this.setState({ showDetails: true });
    };

    handleReportBug = () => {
        console.log(this.state);
        const url: string = getGithubIssueUrl({
            repo: config.repo,
            user: config.repoUser,
            title: 'Something Unexpected Happened: ' + this.state.error?.name,
            body: '' + this.state.error?.message + '\n```{javascript}\n' + this.state.error?.stack + '\n```',
        });
        (async () => {
            await open(url);
        })();
    };

    render = () => {
        const { error, showDetails, hasError, isReported } = this.state;

        if (hasError) {
            return (
                <Result
                    status="500"
                    title={<Typography.Title level={1}>Something Unexpected Happened</Typography.Title>}
                    subTitle={
                        <Paragraph>
                            Sorry, an unrecoverable error occurred
                            {isReported && ' – our engineers have been notified'}. Please try reloading the page, it may
                            have been a temporary glitch.
                        </Paragraph>
                    }
                    extra={
                        <Paragraph>
                            <DocumentTitle title="Fastbot: Something Unexpected Happened" />
                            {this.state.showDetails && (
                                <SyntaxHighlighter language="javascript" style={docco}>
                                    {error?.stack}
                                </SyntaxHighlighter>
                            )}
                            <Paragraph>
                                {showDetails ? (
                                    <Button
                                        style={{
                                            height: '48px',
                                            borderRadius: '0.5em',
                                        }}
                                        onClick={this.handleReportBug}
                                    >
                                        Report a Bug…
                                    </Button>
                                ) : (
                                    <Button
                                        style={{
                                            height: '48px',
                                            borderRadius: '0.5em',
                                        }}
                                        onClick={this.handleShowDetails}
                                    >
                                        Show Details…
                                    </Button>
                                )}
                            </Paragraph>
                        </Paragraph>
                    }
                />
            );
        }

        return this.props.children;
    };
}
