import React from 'react';

export type RequestBody = any;
export type LazyComponentType = React.LazyExoticComponent<React.ComponentType<any>>;

export interface SingleChoice {
    id: string;
    description: string;
}

export interface MultipleChoice {
    id: string;
    description: string;
}

export enum SurveyStatus {
    COMPLETED = 'completed',
    PUBLISHED = 'published',
    IN_PROGRESS = 'in-progress',
}

export interface Meta extends Record<string, any> {
    id: number;
    sid: number;
    createdAt: Date | number;
    modifiedAt: Date | number;
}

export interface Survey extends Meta {
    title: string;
    description: string;
    status: SurveyStatus;
    questionCount: number;
    color: string;
    questions: Question[];
}
export interface SurveySnapShot {
    title: string;
    description: string;
    status: SurveyStatus;
    questionCount: number;
    color: string;
    questions: QuestionSnapshot[];
}

export interface QuestionSnapshot extends Record<string, any> {
    sid: string;
    type: string;
    label: string;
    required: boolean;
    options?: QuestionOptionSnaphot[];
}

export interface QuestionOptionSnaphot extends Record<string, any> {
    id?: string;
    key?: string;
    value?: string;
    custom?: boolean;
}

export interface CreateSurvey {
    title: string;
    description: string;
}

export interface Question extends Record<string, any> {
    sid: string;
    type: string;
    label: string;
    required: boolean;
    options?: QuestionOption[];
}

export interface QuestionOption extends Record<string, any> {
    id?: string;
    key?: string;
    value?: string;
    custom?: boolean;
}

export interface OauthResult {
    source: string;
    oauthToken: string;
    oauthVerifier: string;
}

export class RequestHeaders {
    public static readonly JSON = 'application/json';
    public static readonly TOKEN = 'TOKEN';
    public static readonly CONTENT_TYPE = 'Content-Type';
    public static readonly CONTENT_LENGTH = 'Content-Length';
    public static readonly AUTHORIZATION = 'Authorization';
    public static readonly APPLICATION_FORM_URLENCODED = 'application/x-www-form-urlencoded';
}

export interface RouterProps {
    isExact?: boolean;
    params?: any;
    path?: string;
    url?: string;
}

export interface SiteProps {
    site: Site;
}

export interface Identifiable {
    id?: string | number | null;
    uuid?: string | null;
}

export interface Login {
    username: string;
    password: string;
}

export interface PasswordReset {
    username: string;
}

export interface PasswordResetConfirm {
    token: string;
    newPassword: string;
}

export type Country = {
    zh: string;
    en: string;
    short: string;
    emoji: string;
    phoneCode: string;
};

export interface ChannelData extends Record<string, any> {
    id: string;
    channel: string;
    webhookUrl: string;
    verified?: boolean;
    name?: string;
}
export interface Bot {
    id?: string | number;
    name?: string;
    description?: string;
    url?: string;
    avatar?: string;
    email?: string;
    phone?: string;
    verified?: boolean;
    channels?: ChannelData[];
}

export interface Configuration {
    api: string;
    key: string;
    user: string;
    env: string;
    primaryColor: string;
    publishableKey: string;
    repo: string;
    repoUser: string;
    builder: string;
}

export interface Credentials {
    username: string;
    password: string;
}

export interface User {
    email: string;
    id: number;
    username: string;
    active_subscription?: boolean;
}

export interface TokenResponse {
    token?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
}

export interface JwtTokenResponse {
    access_token: string | null;
    refresh_token: string | null;
    expires_in: number | null;
    created?: Date | null;
}

export interface BreadCrumb {
    icon?: React.ReactNode;
    name: string;
    path: string;
    match?: boolean;
}

export interface Menu {
    path?: string;
    title: string;
    icon?: React.ReactNode;
    exact?: boolean;
    component: LazyComponentType;
    breadcrumbs: BreadCrumb[];
    sideBar?: boolean;
    submenus?: Menu[];
}

export interface Site {
    name: string;
    description: string;
}

export interface Application {
    site: Site;
    menus: Menu[];
}

export interface Timestamp {
    created_at: string;
}

export interface RequestLog extends Timestamp {
    channel: string;
    origin: string;
    uid: string;
    correlation: string;
    sender: string;
    protocol: string;
    remote: string;
    method: string;
    uri: string;
    body: string;
    duration: string;
    status: string;
    host: string;
    path: string;
    scheme: string;
    port: string;
    headers: string;
}

export interface SurveyAnswer extends Meta {
    channel: string;
    participant: number;
    question: string;
    answer: string;
}

export interface ResponseLog extends Timestamp {
    channel: string;
    origin: string;
    request: RequestLog;
    correlation: string;
    sender: string;
    protocol: string;
    remote: string;
    method: string;
    uri: string;
    body: string;
    duration: string;
    status: string;
    host: string;
    path: string;
    scheme: string;
    port: string;
    headers: string;
}
