import React from 'react';
import { Button, Col, Form, FormInstance, Input, Row } from 'antd';
import logo from '../images/logo.svg';
import { Link } from 'react-router-dom';
import { primaryColor } from '../config';
import DocumentTitle from 'react-document-title';
import { LogoComponent } from '../images';

export default class SignUpForm extends React.Component<any, any> {
    private formRef = React.createRef<FormInstance>();

    constructor(props: any) {
        super(props);
    }

    onFinish = () => false;
    onFinishFailed = () => false;

    render() {
        const { site } = this.props;
        return (
            <DocumentTitle title={`Join ${site.name}`}>
                <Row justify="center" align="middle">
                    <Col
                        className={'signup'}
                        xs={24}
                        sm={16}
                        md={12}
                        lg={8}
                        style={{
                            boxShadow: '0px 10px 20px rgba(60,60,60,.1)',
                            padding: '20px',
                            marginTop: '40px',
                            backgroundColor: '#fff',
                        }}
                    >
                        <Form
                            requiredMark={'optional'}
                            initialValues={{ remember: true }}
                            onFinish={this.onFinish}
                            onFinishFailed={this.onFinishFailed}
                        >
                            <Row>
                                <Col span={24} style={{ textAlign: 'center' }}>
                                    <LogoComponent
                                        height="128px"
                                        width="128px"
                                        style={{
                                            textAlign: 'center',
                                            marginTop: '50px',
                                            marginBottom: '50px',
                                        }}
                                    />
                                </Col>
                                <Col span={11}>
                                    <Form.Item
                                        label={
                                            <span
                                                style={{
                                                    fontSize: '1rem',
                                                    fontWeight: 600,
                                                    lineHeight: 1.5,
                                                    textAlign: 'left',
                                                }}
                                            >
                                                First name
                                            </span>
                                        }
                                        labelCol={{
                                            span: 24,
                                            style: {
                                                fontSize: '1rem',
                                                fontWeight: 600,
                                                lineHeight: 1.5,
                                                textAlign: 'left',
                                            },
                                        }}
                                        wrapperCol={{ span: 24 }}
                                        name="first_name"
                                        rules={[{ required: true, message: 'Please input your First Name!' }]}
                                    >
                                        <Input style={{ height: '52px', borderRadius: '0.5em' }} size={'large'} />
                                    </Form.Item>
                                </Col>
                                <Col span={11} offset={2}>
                                    <Form.Item
                                        label={
                                            <span
                                                style={{
                                                    fontSize: '1rem',
                                                    fontWeight: 600,
                                                    lineHeight: 1.5,
                                                    textAlign: 'left',
                                                }}
                                            >
                                                Last name
                                            </span>
                                        }
                                        labelCol={{
                                            span: 24,
                                            style: {
                                                fontSize: '1rem',
                                                fontWeight: 600,
                                                lineHeight: 1.5,
                                                textAlign: 'left',
                                            },
                                        }}
                                        wrapperCol={{ span: 24 }}
                                        name="last_name"
                                        rules={[{ required: true, message: 'Please input your Last name!' }]}
                                    >
                                        <Input style={{ height: '52px', borderRadius: '0.5em' }} size={'large'} />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        label={
                                            <span
                                                style={{
                                                    fontSize: '1rem',
                                                    fontWeight: 600,
                                                    lineHeight: 1.5,
                                                    textAlign: 'left',
                                                }}
                                            >
                                                Username
                                            </span>
                                        }
                                        labelCol={{
                                            span: 24,
                                            style: {
                                                fontSize: '1rem',
                                                fontWeight: 600,
                                                lineHeight: 1.5,
                                                textAlign: 'left',
                                            },
                                        }}
                                        wrapperCol={{ span: 24 }}
                                        name="username"
                                        rules={[{ required: true, message: 'Please input your username!' }]}
                                    >
                                        <Input style={{ height: '52px', borderRadius: '0.5em' }} size={'large'} />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        label={
                                            <span
                                                style={{
                                                    fontSize: '1rem',
                                                    fontWeight: 600,
                                                    lineHeight: 1.5,
                                                    textAlign: 'left',
                                                }}
                                            >
                                                Email address
                                            </span>
                                        }
                                        labelCol={{
                                            span: 24,
                                            style: {
                                                fontSize: '1rem',
                                                fontWeight: 600,
                                                lineHeight: 1.5,
                                                textAlign: 'left',
                                            },
                                        }}
                                        wrapperCol={{ span: 24 }}
                                        name="email"
                                        rules={[{ required: true, message: 'Please input your Email Address!' }]}
                                    >
                                        <Input style={{ height: '52px', borderRadius: '0.5em' }} size={'large'} />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        label={
                                            <label
                                                style={{
                                                    fontSize: '1rem',
                                                    fontWeight: 600,
                                                    lineHeight: 1.5,
                                                    textAlign: 'left',
                                                }}
                                            >
                                                Password
                                            </label>
                                        }
                                        name="password"
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        rules={[{ required: true, message: 'Please input your password!' }]}
                                    >
                                        <Input.Password
                                            style={{ height: '52px', borderRadius: '0.5em' }}
                                            size={'large'}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item>
                                        <Button
                                            block={true}
                                            size={'large'}
                                            style={{
                                                background: primaryColor,
                                                borderColor: primaryColor,
                                                height: '52px',
                                                fontWeight: 600,
                                                color: 'white',
                                                borderRadius: '0.5em',
                                            }}
                                            htmlType="submit"
                                        >
                                            Sign Up
                                        </Button>
                                    </Form.Item>
                                </Col>

                                <Col span={24} style={{ textAlign: 'center' }}>
                                    <label
                                        style={{
                                            fontWeight: 400,

                                            color: 'gray',
                                        }}
                                    >
                                        Already have an account?{' '}
                                        <Link to={'/login'} style={{ color: '#e96122' }}>
                                            {' '}
                                            Sign in
                                        </Link>
                                    </label>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </DocumentTitle>
        );
    }
}
