import React, { RefObject } from 'react';
import { Button, Col, Form, FormInstance, Input, message, Row } from 'antd';
import logo from '../images/logo.svg';
import { Credentials, Site, TokenResponse } from '../models';
import { Link } from 'react-router-dom';
import { serviceLogIn, storeToken } from '../services/AuthService';
import { history } from '../store';
import { primaryColor } from '../config';
import DocumentTitle from 'react-document-title';
import { LogoComponent } from '../images';
import ReCAPTCHA from 'react-google-recaptcha';

export interface SignInProps {
    onChange: (jwt: TokenResponse) => void;
    site: Site;
}

export default class SignInForm extends React.Component<SignInProps, any> {
    private captchaRef: RefObject<ReCAPTCHA>;

    constructor(props: SignInProps) {
        super(props);
        this.captchaRef = React.createRef<ReCAPTCHA>();
    }
    state = {};
    onFinish = (values: any) => {
        const { current } = this.captchaRef;
        if (current) {
            console.log({ values, current });
            current
                .executeAsync()
                .then((recaptchaToken) => {
                    console.log({ recaptchaToken });
                    const body = {
                        ...values,
                        name: 'web',
                        recaptchaToken,
                    };
                    const credentials = body as Credentials;

                    serviceLogIn(credentials)
                        .then((response: any) => {
                            console.log({ response });
                            const tokenResponse = response as TokenResponse;
                            if (tokenResponse && tokenResponse.token) {
                                storeToken(tokenResponse);
                                this.props.onChange(tokenResponse);
                                history.push('/');
                            } else {
                                message.error('Invalid credentials passed');
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            if (navigator.onLine) {
                                message.error('Failed to login. Server connection error').then((r) => console.log(r));
                            } else {
                                message.error('Failed to login. Please check your internet connection');
                            }
                        });
                })
                .catch((err) => {
                    console.log({ err });
                });
        } else {
            console.warn('Current is null');
        }
    };
    onFinishFailed = (e: any) => {
        console.log(e);
        return false;
    };

    render() {
        const { site } = this.props;

        return (
            <DocumentTitle title={`Sign in to ${site.name}`}>
                <Row justify="center" align="middle">
                    <Col
                        className={'signup'}
                        xs={24}
                        sm={16}
                        md={12}
                        lg={8}
                        style={{
                            boxShadow: '0px 10px 20px rgba(60,60,60,.1)',
                            padding: '20px',
                            maxWidth: '480px',
                            marginTop: '40px',
                            backgroundColor: '#fff',
                        }}
                    >
                        <Form
                            requiredMark={'optional'}
                            initialValues={{ remember: true }}
                            onFinish={this.onFinish}
                            onFinishFailed={this.onFinishFailed}
                        >
                            <Row>
                                <Col span={24} style={{ textAlign: 'center' }}>
                                    <LogoComponent
                                        height="128px"
                                        width="128px"
                                        style={{
                                            textAlign: 'center',
                                            marginTop: '50px',
                                            marginBottom: '50px',
                                        }}
                                    />
                                </Col>
                                <Col span={24}>
                                    <Row style={{ paddingBottom: '20px' }}>
                                        <Col
                                            span={12}
                                            style={{
                                                fontSize: '1rem',
                                                fontWeight: 600,
                                                lineHeight: 1.5,
                                            }}
                                        >
                                            Username
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        wrapperCol={{ span: 24 }}
                                        name="username"
                                        rules={[{ required: true, message: 'Please input your username!' }]}
                                    >
                                        <Input style={{ height: '52px', borderRadius: '0.5em' }} size={'large'} />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Row style={{ paddingBottom: '20px' }}>
                                        <Col
                                            span={12}
                                            style={{
                                                fontSize: '1rem',
                                                fontWeight: 600,
                                                lineHeight: 1.5,
                                            }}
                                        >
                                            Password
                                        </Col>
                                        <Col
                                            span={12}
                                            style={{
                                                float: 'right',
                                                textAlign: 'right',
                                                fontSize: '1rem',
                                                fontWeight: 600,
                                                lineHeight: 1.5,
                                            }}
                                        >
                                            <Link to={'/password_reset'} style={{ color: primaryColor }}>
                                                Forgot Password
                                            </Link>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="password"
                                        wrapperCol={{ span: 24 }}
                                        rules={[{ required: true, message: 'Please input your password!' }]}
                                    >
                                        <Input.Password
                                            style={{ height: '52px', borderRadius: '0.5em' }}
                                            size={'large'}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item>
                                        <Button
                                            block={true}
                                            size={'large'}
                                            style={{
                                                background: '#e96122',
                                                borderColor: '#e96122',
                                                height: '52px',
                                                fontWeight: 600,
                                                color: 'white',
                                                borderRadius: '0.5em',
                                            }}
                                            htmlType="submit"
                                        >
                                            Sign In
                                        </Button>
                                    </Form.Item>
                                </Col>

                                <Col span={24} style={{ textAlign: 'center' }}>
                                    <ReCAPTCHA
                                        sitekey="6LfX4FMgAAAAAECa9LHuUe4sjYbGgJKZpunnFLyf"
                                        size="invisible"
                                        ref={this.captchaRef}
                                        onChange={(token) => {
                                            this.setState({ token });
                                        }}
                                    />
                                </Col>

                                <Col span={24} style={{ textAlign: 'center' }}>
                                    <label
                                        style={{
                                            fontWeight: 400,
                                            color: 'gray',
                                        }}
                                    >
                                        No account yet?{' '}
                                        <Link to={'/signup'} style={{ color: '#e96122' }}>
                                            Sign Up
                                        </Link>
                                    </label>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </DocumentTitle>
        );
    }
}
