import { Configuration } from '../models';
export function configuration(): Configuration {
    return {
        api: process.env.REACT_APP_API_ENDPOINT || 'http://localhost:8000/v1',
        repo: 'fastbot',
        repoUser: 'getfastbot',
        key: 'key',
        user: 'user',
        builder: 'builder',
        env: process.env.NODE_ENV || 'production',
        primaryColor: '#e96122',
        publishableKey:
            process.env.STRIPE_PUBLISHABLE_KEY ||
            'pk_test_51IJlpgKhr0ApzevZ2GZanMIJKNR1Fx8CtKBXwxmFXePAeE1v2lJvt1WpHajCozQm8106BjHJSGkoWk8uxuaKHTkL00435SxSFs',
    };
}

const config = configuration();
const { primaryColor, env, key, user, api } = config;
export { primaryColor, env, key, user, api, config };
export default config;
