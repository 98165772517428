import React from 'react';
import ReactDOM from 'react-dom';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import(/* webpackChunkName: "fetch-polyfill" */ 'whatwg-fetch');
import(/* webpackChunkName: "react-app-polyfill" */ 'react-app-polyfill/ie9');
import(/* webpackChunkName: "react-app-polyfill" */ 'react-app-polyfill/ie11');
import(/* webpackChunkName: "react-app-polyfill" */ 'react-app-polyfill/stable');
import(/* webpackChunkName: "react-app-polyfill" */ 'core-js/stable');
import(/* webpackChunkName: "react-app-polyfill" */ 'core-js/features/url');
import(/* webpackChunkName: "react-app-polyfill" */ 'core-js/features/url-search-params');
import(/* webpackChunkName: "react-app-polyfill" */ 'regenerator-runtime/runtime');
import(/* webpackChunkName: "intl-polyfill" */ '@formatjs/intl-relativetimeformat/polyfill');
import(/* webpackChunkName: "intl-polyfill" */ '@formatjs/intl-relativetimeformat/locale-data/en');
import(/* webpackChunkName: "intl-polyfill" */ '@formatjs/intl-numberformat/polyfill');
import(/* webpackChunkName: "intl-polyfill" */ '@formatjs/intl-numberformat/locale-data/en');
import(/* webpackChunkName: "blob-polyfill" */ 'blob-polyfill');
import(/* webpackChunkName: "blob-polyfill" */ 'canvas-toBlob');
import(/* webpackChunkName: "observers-polyfill" */ 'intersection-observer');
import(/* webpackChunkName: "observers-polyfill" */ 'resize-observer-polyfill');
import(/* webpackChunkName: "highcharts" */ 'highcharts');

import 'antd/dist/antd.css';
import './index.css';

import { stopReportingRuntimeErrors } from 'react-error-overlay';
import App from './App';
import reportWebVitals from './reportWebVitals';

// if (process.env.NODE_ENV === 'development') {
//     stopReportingRuntimeErrors(); // disables error overlays
// }

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
