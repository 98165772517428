import React from 'react';
import { Button, Col, Form, Input, message, Row } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone, UserOutlined } from '@ant-design/icons';
import DocumentTitle from 'react-document-title';
import { resetPasswordConfirm } from '../services/AuthService';
import { FormInstance } from 'antd/es/form';
import { primaryColor } from '../config';
import FormLogo from './FormLogo';
import { LogoComponent } from '../images';

export default class PasswordResetFormConfirm extends React.Component<any, any> {
    private formRef = React.createRef<FormInstance>();
    onFinish = () => {
        const { current } = this.formRef;
        if (current) {
            current.validateFields().then((values) => {
                const { newPassword } = values;
                const {
                    params: { token },
                } = this.props.match;
                console.log({ token, newPassword });
                resetPasswordConfirm({ token, newPassword })
                    .then((data) => {
                        message.success('Password updated successfully. Please login');
                    })
                    .catch((e) => {
                        console.log({ e });
                        if (navigator.onLine) {
                            message.error('Failed to reset password. Server connection error');
                        } else {
                            message.error('Failed to reset password. Please check your internet connection');
                        }
                    });
            });
        }
    };
    onFinishFailed = () => false;
    render() {
        const { site } = this.props;

        return (
            <DocumentTitle title={`${site.name}: Password Reset Confirm`}>
                <Row>
                    <Col
                        className={'signup'}
                        xs={24}
                        sm={16}
                        md={12}
                        lg={8}
                        style={{
                            boxShadow: '0px 10px 20px rgba(60,60,60,.1)',
                            padding: '20px',
                            marginTop: '40px',
                            backgroundColor: '#fff',
                        }}
                    >
                        <Form
                            requiredMark={'optional'}
                            initialValues={{ remember: true }}
                            onFinish={this.onFinish}
                            onFinishFailed={this.onFinishFailed}
                        >
                            <Row>
                                <Col span={24} style={{ textAlign: 'center' }}>
                                    <LogoComponent
                                        height="128px"
                                        width="128px"
                                        style={{
                                            textAlign: 'center',
                                            marginTop: '50px',
                                            marginBottom: '50px',
                                        }}
                                    />
                                </Col>
                                <Col span={24}>
                                    <Form.Item name="newPassword" rules={[{ required: true }]}>
                                        <Input.Password
                                            prefix={<UserOutlined />}
                                            placeholder="New Password"
                                            iconRender={(visible) =>
                                                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                                            }
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item>
                                        <Button
                                            block={true}
                                            size={'large'}
                                            style={{
                                                background: primaryColor,
                                                borderColor: primaryColor,
                                                height: '52px',
                                                fontWeight: 600,
                                                color: 'white',
                                                borderRadius: '0.5em',
                                            }}
                                            htmlType="submit"
                                        >
                                            Confirm Password
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </DocumentTitle>
        );
    }
}
