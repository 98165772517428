import React from 'react';

import {
    BotDetail,
    Page404,
    BotList,
    Plans,
    PaymentSuccess,
    PaymentCancel,
    LogList,
    OauthResult,
    SurveyList,
    SurveyDetail,
    SurveyBuilder,
    SurveyResults,
    UploadFile,
    FileDropZone,
    QRCodeView,
} from '../components';
import { Application } from '../models';
import { ActivityComponent, RobotComponent } from '../images';
import { SnippetsOutlined } from '@ant-design/icons';

export default function routes(): Application {
    return {
        site: {
            name: 'Fastbot',
            description: 'A unified platform for building enterprise-grade conversational experiences',
        },
        menus: [
            {
                path: '/',
                title: 'Bots',
                icon: <RobotComponent height="1.25em" width="1.25em" fill={'white'} />,
                exact: true,
                component: BotList,
                sideBar: true,
                breadcrumbs: [],
            },
            {
                path: '/surveys',
                title: 'Surveys',
                icon: <SnippetsOutlined />,
                exact: true,
                component: SurveyList,
                sideBar: true,
                breadcrumbs: [],
            },
            {
                path: '/surveys/:id',
                title: 'Bots',
                icon: <RobotComponent height="1.25em" width="1.25em" fill={'white'} />,
                exact: true,
                component: SurveyDetail,
                sideBar: false,
                breadcrumbs: [
                    {
                        icon: <SnippetsOutlined />,
                        name: 'Surveys',
                        path: '/surveys',
                    },
                    {
                        path: '/surveys/{id}',
                        name: 'Surveys&lt;{id}&gt;',
                        match: true,
                    },
                ],
            },
            {
                path: '/surveys/:id/results',
                title: 'Bots',
                icon: <RobotComponent height="1.25em" width="1.25em" fill={'white'} />,
                exact: true,
                component: SurveyResults,
                sideBar: false,
                breadcrumbs: [
                    {
                        icon: <SnippetsOutlined />,
                        name: 'Surveys',
                        path: '/surveys',
                    },
                    {
                        path: '/surveys/{id}',
                        name: 'Surveys&lt;{id}&gt;',
                        match: true,
                    },
                ],
            },
            {
                path: '/surveys/:id/builder',
                title: 'Bots',
                icon: <RobotComponent height="1.25em" width="1.25em" fill={'white'} />,
                exact: true,
                component: SurveyBuilder,
                sideBar: false,
                breadcrumbs: [
                    {
                        icon: <SnippetsOutlined />,
                        name: 'Surveys',
                        path: '/surveys',
                    },
                    {
                        path: '/surveys/{id}',
                        name: 'Surveys&lt;{id}&gt;',
                        match: true,
                    },
                ],
            },
            {
                path: '/oauth/result',
                title: 'Oauth Result',
                exact: true,
                component: OauthResult,
                breadcrumbs: [],
            },
            {
                path: '/uploads',
                title: 'Upload Documents',
                exact: true,
                component: QRCodeView,
                breadcrumbs: [],
                icon: <RobotComponent height="1.25em" width="1.25em" fill={'white'} />,
                sideBar: true,
            },
            {
                path: '/plans',
                title: 'Plans',
                icon: <RobotComponent height="1.25em" width="1.25em" fill={'white'} />,
                exact: true,
                component: Plans,
                breadcrumbs: [],
            },
            {
                path: '/activity',
                title: 'Activity',
                icon: <ActivityComponent height={'1.25em'} width={'1.25em'} fill={'white'} />,
                exact: true,
                sideBar: true,
                component: LogList,
                breadcrumbs: [],
            },
            {
                path: '/payments/success',
                title: 'Plans',
                icon: <RobotComponent height="1.25em" width="1.25em" fill={'white'} />,
                exact: true,
                component: PaymentSuccess,
                breadcrumbs: [],
            },
            {
                path: '/payments/cancel',
                title: 'Plans',
                icon: <RobotComponent height="1.25em" width="1.25em" fill={'white'} />,
                exact: true,
                component: PaymentCancel,
                breadcrumbs: [],
            },
            {
                path: '/bots/:id',
                title: 'Bots',
                icon: <RobotComponent height="1.25em" width="1.25em" fill={'white'} />,
                exact: true,
                component: BotDetail,
                sideBar: false,
                breadcrumbs: [
                    {
                        icon: <RobotComponent height="1.25em" width="1.25em" fill={'white'} />,
                        name: 'Bots',
                        path: '/',
                    },
                    {
                        path: '/bots/{id}',
                        name: 'Bots&lt;{id}&gt;',
                        match: true,
                    },
                ],
            },
            {
                path: '*',
                title: 'Fastbot: Page you visited does not exist.',
                icon: <RobotComponent height="1.25em" width="1.25em" />,
                component: Page404,
                breadcrumbs: [],
            },
        ],
    };
}
