import React from 'react';
import { LazyComponentType } from '../models';

const Placeholder: LazyComponentType = React.lazy(() => import('./Placeholder'));
const BotList: LazyComponentType = React.lazy(() => import('./BotList'));
const UploadFile: LazyComponentType = React.lazy(() => import('./UploadFile'));
const BotDetail: LazyComponentType = React.lazy(() => import('./BotDetail'));
const Plans: LazyComponentType = React.lazy(() => import('./Plans'));
const PaymentCancel: LazyComponentType = React.lazy(() => import('./PaymentCancel'));
const PaymentSuccess: LazyComponentType = React.lazy(() => import('./PaymentSuccess'));
const LogList: LazyComponentType = React.lazy(() => import('./LogList'));
const Page404: LazyComponentType = React.lazy(() => import('./Page404'));
const OauthResult: LazyComponentType = React.lazy(() => import('./OauthResult'));
const SurveyList: LazyComponentType = React.lazy(() => import('./SurveyList'));
const SurveyBuilder: LazyComponentType = React.lazy(() => import('./SurveyBuilder'));
const SurveyDetail: LazyComponentType = React.lazy(() => import('./SurveyDetail'));
const SurveyResults: LazyComponentType = React.lazy(() => import('./SurveyResults'));
const FileDropZone: LazyComponentType = React.lazy(() => import('./FileDropZone'));
const QRCodeView: LazyComponentType = React.lazy(() => import('./QRCodeView'));

export {
    Placeholder,
    BotList,
    BotDetail,
    Plans,
    PaymentSuccess,
    PaymentCancel,
    LogList,
    Page404,
    OauthResult,
    SurveyList,
    SurveyBuilder,
    SurveyDetail,
    SurveyResults,
    UploadFile,
    FileDropZone,
    QRCodeView,
};
