import { routerMiddleware } from 'connected-react-router';
// import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import createReducers from '../reducers';
import { config } from '../config';
import { createBrowserHistory } from 'history';
// import logger from 'redux-logger';

export const history = createBrowserHistory();

export type ComposeType = typeof compose;
declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION__?: ComposeType;
    }
}

// export default () => {
//     return createStore(
//         createReducers(history),
//         config.env === 'production'
//             ? compose(
//                 // applyMiddleware(thunk),
//                 applyMiddleware(routerMiddleware(history)))
//             : compose(
//                 // applyMiddleware(thunk),
//                 // applyMiddleware(logger),
//                 applyMiddleware(routerMiddleware(history)),
//                 window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f: any) => f,
//             ),
//     );
// };

export default () => {
    return createStore(
        createReducers(history),
        config.env === 'production'
            ? compose(applyMiddleware(routerMiddleware(history)))
            : compose(
                  applyMiddleware(routerMiddleware(history)),
                  window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f: any) => f,
              ),
    );
};
